import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { differenceInCalendarDays, getDaysInMonth } from "date-fns";

import {getEventForPlanningFromAPI, getTemplateFromAPI, updateEventFromAPI} from "../../helpers/api";
import {
	calcEventStartEndDates,
	calcResourceStartEndDates,
	findOverlappingEvents,
	getEventById,
	getEventByResourceId,
	getResourceById,
	getTimeStepInPx,
	isEventId,
	calcAvailableResourcesQty, shiftEventDatesToCalendarStartTime, eventMovable,
} from "../../helpers/calendarHelpers";
import {
    ResizeReffProps,
    HandlersContext,
    HandlersContextType,
    initHandlersContext,
} from "./Calendar";
import CalendarEventRow from "./CalendarEventRow";
import { composeDateFromBaseDate } from "../../helpers/dateHelpers";
import {
	getCalendarEndDate,
	getCalendarStartDate, getLoading,
	getNewEvent, getSelectedEventIdForPlanning,
	getSelectedTemplateId,
	getWorkTimeEnd,
	getWorkTimeStart,
} from "../../redux/selectors/calendarSelectors";
import {
	getBranchFilter,
	getCalendarDisplayType, getCalendarId,
	getPickedDate,
	getTemplateFilter,
	getTemplateGroupFilter,
} from "../../redux/selectors/filterSelectors";
import {setCreateEventMode, setLoading, setNewEvent, setPickedTemplate, setSelectedTemplateId} from "../../redux/actions/calendarActions";
import {
	getGrouppedTemplatesFilterOptions,
} from "../../redux/selectors/filterOptionsSelectors";
import {
	setAvailableResourcesQty,
	setBranchItems as setBranchItemsRedux,
	setBranchItemsConflicts
} from "../../redux/actions/branchItemsActions";
import {BranchItemType} from "../../helpers/dataProcessor";
import {getEvents} from "../../redux/selectors/eventsSelectors";
import {
	setEventResourcesStartEndDates,
	setEvents,
	setEventStartEndDate,
	setEventsUpdated,
	updateEvent,
	setEventResourcesCurrentStock,
} from "../../redux/actions/eventsActions";
import Loader from "../Loader/Loader";
import {getAvailableResourcesQty} from "../../redux/selectors/branchItemsSelectors";
import {setPickedDateFilter} from "../../redux/actions/filterActions";
import { useTranslation } from "react-i18next";

const CreateEventCalendarBody = () => {
    const dispatch = useDispatch();
	const events = useSelector(getEvents);
    const calendarDisplayType = useSelector(getCalendarDisplayType);
	const calendarId = useSelector(getCalendarId);
	const branchId = useSelector(getBranchFilter);
	const templateGroupId = useSelector(getTemplateGroupFilter);
	const templateId = useSelector(getTemplateFilter);
    const pickedDate = useSelector(getPickedDate);
    const calendarStartDate = useSelector(getCalendarStartDate);
    const calendarEndDate = useSelector(getCalendarEndDate);
	const isLoading = useSelector(getLoading);
    const workTimeStart = useSelector(getWorkTimeStart);
    const workTimeEnd = useSelector(getWorkTimeEnd);
    const newEvent = useSelector(getNewEvent);
    const selectedTemplateId = useSelector(getSelectedTemplateId);
	const selectedEventIdForPlanning = useSelector(getSelectedEventIdForPlanning);
    const templates = useSelector(getGrouppedTemplatesFilterOptions);
	const availableResourcesQty = useSelector(getAvailableResourcesQty);
	const { t } = useTranslation();
	const [firstLoad, setFirstLoad] = useState(true);
    const [branchItems, setBranchItems] = useState<BranchItemType[]>([]);
    // const [resources, setResources] = useState<ResourceType[]>([]);
    const [handlersContextValue, setHandlersContextValue] = useState<
        HandlersContextType
    >(initHandlersContext);
    const reffProps = useRef<ResizeReffProps>({
        activeTimeBoxId: null,
        resizeLastUpdateScreenX: null,
        resizeDeltaX: 0,
        isResizeLeftActive: false,
        isResizeRightActive: false,
        resizeStartTime: null,
        resizeEndTime: null,
    });
	const eventsRef = useRef(events);

	useEffect(() => {
		eventsRef.current = events;
	}, [events]);

	useEffect(() => {
		dispatch(setBranchItemsConflicts(findOverlappingEvents(events, branchItems)));
	}, [dispatch, newEvent, events, branchItems]);

	// useEffect(() => {
	// 	const virtualEvent = events.find((e) => e.id === 'virtual');
	// 	if (virtualEvent?.locked) return;

	// 	const availableResourcesQtyNow = calcAvailableResourcesQty(events, branchItems);
	// 	const availableVirtResourcesQty = pickBy(availableResourcesQtyNow, (_, key) => key.startsWith('evirtual'));
	// 	if (!isEqual(availableResourcesQty, availableResourcesQtyNow)) {
	// 		// console.log(availableResourcesQty, availableResourcesQtyNow);
	// 		dispatch(setAvailableResourcesQty(availableResourcesQtyNow));
	// 		dispatch(setEventResourcesCurrentStock(availableVirtResourcesQty));
	// 	}
	// }, [dispatch, events, branchItems, availableResourcesQty]);

	useEffect(() => {
		if (!selectedEventIdForPlanning || !calendarId) return;
		dispatch(setLoading(true));
		getEventForPlanningFromAPI(selectedEventIdForPlanning, calendarStartDate, calendarEndDate, calendarId, branchId)
			.then((data) => {
				const {event, events, branchItems} = data;
				console.log('firstLoad', firstLoad);
				if (firstLoad) {
					const pickedDate = new Date(event.startDate.setHours(0, 0, 0, 0));
					dispatch(setPickedDateFilter(pickedDate));
					setFirstLoad(false);
					return;
				}
				const diffDays = differenceInCalendarDays(
					event.endDate,
					event.startDate
				);
				const res = event.resources.map((r) => {
					if (!r.startDate || !r.endDate) {
						return r;
					}
					const resDiffDays = differenceInCalendarDays(
						r.endDate,
						r.startDate
					);
					return {
						...r,
						startDate: composeDateFromBaseDate(
							pickedDate,
							pickedDate.getDate(),
							r.startDate.getHours(),
							r.startDate.getMinutes()
						),
						endDate: composeDateFromBaseDate(
							pickedDate,
							pickedDate.getDate() + resDiffDays,
							r.endDate.getHours(),
							r.endDate.getMinutes()
						),
					};
				});
				const pickedEvent = {
					...event,
					// calendarId: calendarId || '1',
					branchId: branchId || event.branchId,
					startDate: composeDateFromBaseDate(
						pickedDate,
						pickedDate.getDate(),
						event.startDate.getHours(),
						event.startDate.getMinutes()
					),
					endDate: composeDateFromBaseDate(
						pickedDate,
						pickedDate.getDate() + diffDays,
						event.endDate.getHours(),
						event.endDate.getMinutes()
					),
					resources: res,
				};

				setBranchItems(branchItems);
				dispatch(setEvents([pickedEvent, ...events]))
				dispatch(setBranchItemsRedux(branchItems));
				dispatch(setLoading(false));
			})
			.catch((err) => {
				console.log(err);
				dispatch(setLoading(false));
			});
	}, [selectedEventIdForPlanning, calendarStartDate, calendarEndDate, pickedDate, calendarId, branchId, branchId, firstLoad, dispatch]);

	useEffect(() => {
		if (calendarId === '-1' || !calendarId) {
			dispatch(setNewEvent(null));
			dispatch(setSelectedTemplateId(null));
			dispatch(setCreateEventMode(false));
			alert(t('please_select_calendar'));
			return;
		}

		if(selectedTemplateId !== 'new_template') return;
		if(!branchId || !templateGroupId || !templateId) return;
		const billNr = templateId.split('_')[1];
		const flattenTemplates = templates.flatMap((group) => group.options);
		const pickedTemplate = flattenTemplates.find((t) => t.branchNr === branchId && t.groupNr === templateGroupId && t.billNr === billNr);
		if(!pickedTemplate) return;
		dispatch(setPickedTemplate(pickedTemplate));
		dispatch(setLoading(true));
		getTemplateFromAPI(pickedTemplate.billNr, pickedTemplate.branchNr, pickedTemplate.hostNr, calendarStartDate, calendarEndDate, calendarId)
		.then((template) => {
			const {event, otherEvents, branchItems} = template;
			console.log(event);
			if (!event.templateId) {
				alert('Invalid template!');
				dispatch(setSelectedTemplateId(null));
				dispatch(setCreateEventMode(false));
			}

			const diffDays = differenceInCalendarDays(
				event.endDate,
				event.startDate
			);

			let templateEvent = {
				...event,
				name: '',
				originalTemplateName: '',
				sourceTemplateName: '',
				description: '',
				minVisitors: 0,
				maxVisitors: 0,
				currentVisitors: 0,
				offlineVisitors: 0,
				offsetOffline: 0,
				calendarId: calendarId || '1',
				branchId: branchId || event.branchId,
				startDate: composeDateFromBaseDate(
					pickedDate,
					pickedDate.getDate(),
					event.startDate.getHours(),
					event.startDate.getMinutes()
				),
				endDate: composeDateFromBaseDate(
					pickedDate,
					pickedDate.getDate() + diffDays,
					event.endDate.getHours(),
					event.endDate.getMinutes()
				),
				resources: [],
				status: '5',
				asNewTemplate: true,
			};

			// @ts-ignore
			templateEvent = shiftEventDatesToCalendarStartTime(workTimeStart, pickedDate, templateEvent);
			console.log(templateEvent);
			dispatch(
				setNewEvent(templateEvent)
			);
			setBranchItems(branchItems);
			dispatch(setEvents([templateEvent, ...otherEvents]))
			dispatch(setBranchItemsRedux(branchItems));
			dispatch(setLoading(false));
		})
		.catch((err) => {
			console.log(err);
			dispatch(setNewEvent(null));
			dispatch(setLoading(false));
		});
	}, [selectedTemplateId, calendarStartDate, pickedDate, calendarId, branchId, templates, dispatch]);

	useEffect(() => {
		if (!selectedTemplateId) return;
		if (calendarId === '-1' || !calendarId) {
			dispatch(setNewEvent(null));
			dispatch(setSelectedTemplateId(null));
			dispatch(setCreateEventMode(false));
			alert(t('please_select_calendar'));
			return;
		}
		const flattenTemplates = templates.flatMap((group) => group.options);
		const pickedTemplate = flattenTemplates.find((t) => t.value === selectedTemplateId);
		if (pickedTemplate) {
			dispatch(setLoading(true));
			getTemplateFromAPI(pickedTemplate.billNr, pickedTemplate.branchNr, pickedTemplate.hostNr, calendarStartDate, calendarEndDate, calendarId)
				.then((template) => {
					const {event, otherEvents, branchItems} = template;
					console.log(event);
					if (!event.templateId) {
						alert('Invalid template!');
						dispatch(setSelectedTemplateId(null));
						dispatch(setCreateEventMode(false));
					}
					const diffDays = differenceInCalendarDays(
						event.endDate,
						event.startDate
					);
					const res = event.resources.map((r) => {
						if (!r.startDate || !r.endDate) {
							return r;
						}
						const resDiffDays = differenceInCalendarDays(
							r.endDate,
							r.startDate
						);
						return {
							...r,
							startDate: composeDateFromBaseDate(
								pickedDate,
								pickedDate.getDate(),
								r.startDate.getHours(),
								r.startDate.getMinutes()
							),
							endDate: composeDateFromBaseDate(
								pickedDate,
								pickedDate.getDate() + resDiffDays,
								r.endDate.getHours(),
								r.endDate.getMinutes()
							),
							eventId: 'virtual',
							// color: "rgba(255,255,255,0.7)",
							// color: "FFFFFF",
						};
					});
					let templateEvent = {
						...event,
						name: pickedTemplate.subject,
						calendarId: calendarId || '1',
						branchId: branchId || event.branchId,
						startDate: composeDateFromBaseDate(
							pickedDate,
							pickedDate.getDate(),
							event.startDate.getHours(),
							event.startDate.getMinutes()
						),
						endDate: composeDateFromBaseDate(
							pickedDate,
							pickedDate.getDate() + diffDays,
							event.endDate.getHours(),
							event.endDate.getMinutes()
						),
						// color: "000000",
						// color: "rgba(0, 0, 0, 0.7)",
						resources: res,
					};

					templateEvent = shiftEventDatesToCalendarStartTime(workTimeStart, pickedDate, templateEvent);
					console.log(templateEvent);
					dispatch(
						setNewEvent(templateEvent)
					);
					// const resourceIds = res.reduce((arr: string[], r) => {
					// 	return r.itemId ? [...arr, r.itemId] : arr;
					// }, []);
					// setResourceIds(resourceIds);
					// setOtherEvents(otherEvents);
					setBranchItems(branchItems);
					dispatch(setEvents([templateEvent, ...otherEvents]))
					dispatch(setBranchItemsRedux(branchItems));
					dispatch(setLoading(false));
				})
				.catch((err) => {
					console.log(err);
					dispatch(setNewEvent(null));
					dispatch(setLoading(false));
				});
		}

	}, [selectedTemplateId, calendarStartDate, pickedDate, calendarId, branchId, templates, dispatch]);

	// Used for dragging by left or right event/resource corner
	const mouseUpHandler = useCallback(
		async (e: MouseEvent) => {
			if (
				reffProps.current.isResizeLeftActive ||
				reffProps.current.isResizeRightActive
			) {
				const updatedEvents = eventsRef.current.filter(
					(e) => e.updated
				);
				reffProps.current.isResizeLeftActive = false;
				reffProps.current.isResizeRightActive = false;
				reffProps.current.resizeLastUpdateScreenX = null;
				console.log('mouseUpHandler', updatedEvents, eventsRef);
				if (updatedEvents.length) {
					for (const event of updatedEvents) {
						if (event.id === 'virtual') continue;
						await updateEventFromAPI(event);
						dispatch(updateEvent(event));
						// console.log(`Event ${event.id} updated`);
					}
					dispatch(setEventsUpdated(false));
				}
			}
		},
		[dispatch, events]
	);

	// Used for dragging by left or right event/resource corner
	const mouseMoveHandler = useCallback(
		(e: MouseEvent) => {
			if (
				reffProps.current.resizeLastUpdateScreenX &&
				reffProps.current.activeTimeBoxId &&
				reffProps.current.resizeStartTime &&
				reffProps.current.resizeEndTime &&
				(reffProps.current.isResizeLeftActive ||
					reffProps.current.isResizeRightActive)
			) {
				reffProps.current.resizeDeltaX =
					e.screenX - reffProps.current.resizeLastUpdateScreenX;
				const daysInMonth = getDaysInMonth(calendarStartDate);
				if (
					isEventId(
						reffProps.current.activeTimeBoxId,
						eventsRef.current
					)
				) {
					const event = getEventById(
						reffProps.current.activeTimeBoxId,
						eventsRef.current
					);
					const update = calcEventStartEndDates(
						reffProps.current.resizeDeltaX,
						event,
						calendarDisplayType,
						workTimeStart,
						workTimeEnd,
						daysInMonth,
						reffProps.current.isResizeLeftActive
							? {
								updateStartDate: true,
								updateEndDate: false,
							}
							: {
								updateStartDate: false,
								updateEndDate: true,
							}
					);
					if (update) {
						dispatch(
							setEventStartEndDate(
								update.eventUpdate.eventId,
								update.eventUpdate.startDate,
								update.eventUpdate.endDate
							)
						);
						const diff =
							Math.floor(
								reffProps.current.resizeDeltaX -
								update.stepCount *
								getTimeStepInPx(
									calendarDisplayType,
									workTimeStart,
									workTimeEnd,
									daysInMonth
								)
							) * -1;
						reffProps.current.resizeLastUpdateScreenX =
							e.screenX + diff;
					}
				} else {
					const resource = getResourceById(
						reffProps.current.activeTimeBoxId,
						eventsRef.current
					);
					const update = calcResourceStartEndDates(
						reffProps.current.resizeDeltaX,
						resource,
						calendarDisplayType,
						workTimeStart,
						workTimeEnd,
						daysInMonth,
						reffProps.current.isResizeLeftActive
							? {
								updateStartDate: true,
								updateEndDate: false,
							}
							: {
								updateStartDate: false,
								updateEndDate: true,
							}
					);
					if (update) {
						const event = getEventByResourceId(
							reffProps.current.activeTimeBoxId,
							eventsRef.current
						);
						if (
							event
							&& !(event.startDate > update.resourceUpdate.startDate)
							&& !(event.endDate < update.resourceUpdate.endDate)
						) {
							dispatch(
								setEventResourcesStartEndDates(
									event.id,
									[update.resourceUpdate],
									true
								)
							);
							const diff =
								Math.floor(
									reffProps.current.resizeDeltaX -
									update.stepCount *
									getTimeStepInPx(
										calendarDisplayType,
										workTimeStart,
										workTimeEnd,
										daysInMonth
									)
								) * -1;
							reffProps.current.resizeLastUpdateScreenX =
								e.screenX + diff;
						}
					}
				}
			}
		},
		[
			reffProps,
			calendarDisplayType,
			workTimeStart,
			workTimeEnd,
			calendarStartDate,
			dispatch,
		]
	);

    useEffect(() => {
        window.addEventListener("mousemove", mouseMoveHandler);
        window.addEventListener("mouseup", mouseUpHandler);
        return () => {
            window.removeEventListener("mousemove", mouseMoveHandler);
            window.removeEventListener("mouseup", mouseUpHandler);
        };
    }, [mouseMoveHandler, mouseUpHandler]);

    // useEffect(() => {
    //     resourceIds.length &&
    //         getResourcesFromAPI({
    //             from: calendarStartDate,
    //             to: calendarEndDate,
    //             ids: resourceIds,
    //         })
    //             .then((res) => {
    //                 setResources(res);
    //             })
    //             .catch((err) => console.log(err));
    // }, [resourceIds, calendarStartDate, calendarEndDate]);

	const handleDrag = useCallback(
		(e: any, ui: any) => {
			if (isEventId(ui.node.id, eventsRef.current)) {
				const event = getEventById(ui.node.id, eventsRef.current);
				if (!eventMovable(event)) {
                    // alert('Event with order cannot be moved');
                    return;
                }
				const update = calcEventStartEndDates(
					ui.deltaX,
					event,
					calendarDisplayType,
					workTimeStart,
					workTimeEnd,
					getDaysInMonth(calendarStartDate)
				);
				if (update) {
					dispatch(
						setEventResourcesStartEndDates(
							ui.node.id,
							update.resourcesUpdates,
							false
						)
					);
					dispatch(
						setEventStartEndDate(
							update.eventUpdate.eventId,
							update.eventUpdate.startDate,
							update.eventUpdate.endDate
						)
					);
				}
			} else {
				const resource = getResourceById(ui.node.id, eventsRef.current);
				const event = getEventById(resource?.eventId || '', eventsRef.current);
                if (!eventMovable(event)) {
                    // alert('Event with order cannot be moved');
                    return;
                }
				const update = calcResourceStartEndDates(
					ui.deltaX,
					resource,
					calendarDisplayType,
					workTimeStart,
					workTimeEnd,
					getDaysInMonth(calendarStartDate)
				);
				if (update) {
					const event = getEventByResourceId(
						ui.node.id,
						eventsRef.current
					);
					if (
						event
						&& !(event.startDate > update.resourceUpdate.startDate)
						&& !(event.endDate < update.resourceUpdate.endDate)
					) {
						dispatch(
							setEventResourcesStartEndDates(
								event.id,
								[update.resourceUpdate],
								true
							)
						);
					}
				}
			}
		},
		[
			calendarDisplayType,
			workTimeStart,
			workTimeEnd,
			calendarStartDate,
			dispatch,
		]
	);

	const handleDragStop = useCallback(
		async (e: any, ui: any) => {
			const updatedEvents = eventsRef.current.filter((e) => e.updated);
			if (updatedEvents.length) {
				for (const event of updatedEvents) {
					if (event.id === 'virtual') continue;
					await updateEventFromAPI(event);
					dispatch(updateEvent(event));
					// console.log(`Event ${event.id} updated`);
				}
				dispatch(setEventsUpdated(false));
			}
		},
		[dispatch, events]
	);

	const handleLeftResizerMouseDown = useCallback(
		(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			const timeBoxId =
				e.currentTarget.parentElement &&
				e.currentTarget.parentElement.parentElement
					? e.currentTarget.parentElement.parentElement.id
					: null;
			if (!timeBoxId) {
				return;
			}
			if (isEventId(timeBoxId, eventsRef.current)) {
				const event = getEventById(timeBoxId, eventsRef.current);
				if (!eventMovable(event)) {
                    // alert('Event with order cannot be moved');
                    return;
                }
				reffProps.current.resizeStartTime = event && event.startDate;
				reffProps.current.resizeEndTime = event && event.endDate;
			} else {
				const resource = getResourceById(timeBoxId, eventsRef.current);
				const event = getEventById(resource?.eventId || '', eventsRef.current);
                if (!eventMovable(event)) {
                    // alert('Event with order cannot be moved');
                    return;
                }
				reffProps.current.resizeStartTime =
					resource && resource.startDate;
				reffProps.current.resizeEndTime = resource && resource.endDate;
			}
			reffProps.current.activeTimeBoxId = timeBoxId;
			reffProps.current.isResizeLeftActive = true;
			reffProps.current.resizeLastUpdateScreenX = e.screenX;
		},
		[]
	);

	const handleRightResizerMouseDown = useCallback(
		(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			const timeBoxId =
				e.currentTarget.parentElement &&
				e.currentTarget.parentElement.parentElement
					? e.currentTarget.parentElement.parentElement.id
					: null;
			if (!timeBoxId) {
				return;
			}
			if (isEventId(timeBoxId, eventsRef.current)) {
				const event = getEventById(timeBoxId, eventsRef.current);
				if (!eventMovable(event)) {
                    // alert('Event with order cannot be moved');
                    return;
                }
				reffProps.current.resizeStartTime = event && event.startDate;
				reffProps.current.resizeEndTime = event && event.endDate;
			} else {
				const resource = getResourceById(timeBoxId, eventsRef.current);
				const event = getEventById(resource?.eventId || '', eventsRef.current);
                if (!eventMovable(event)) {
                    // alert('Event with order cannot be moved');
                    return;
                }
				reffProps.current.resizeStartTime =
					resource && resource.startDate;
				reffProps.current.resizeEndTime = resource && resource.endDate;
			}
			reffProps.current.activeTimeBoxId = timeBoxId;
            reffProps.current.isResizeRightActive = true;
            reffProps.current.resizeLastUpdateScreenX = e.screenX;
		},
		[]
	);

    useEffect(() => {
        setHandlersContextValue({
            handleDrag,
            handleDragStop,
            handleLeftResizerMouseDown,
            handleRightResizerMouseDown,
        });
    }, [
        handleDrag,
        handleDragStop,
        handleLeftResizerMouseDown,
        handleRightResizerMouseDown,
    ]);

    return !isLoading ? (
        <HandlersContext.Provider value={handlersContextValue}>
            {/*{newEvent && (*/}
            {/*    <CalendarEventRow*/}
            {/*        events={[*/}
            {/*            {*/}
            {/*                ...newEvent,*/}
            {/*                resources: [...resources, ...newEvent.resources],*/}
            {/*            },*/}
            {/*        ]}*/}
            {/*        childEvents={[]} // TODO: can template be parent event??*/}
            {/*        showCheckbox={false}*/}
            {/*        showDetailIcon={false}*/}
            {/*    />*/}
            {/*)}*/}
			{events.map((event, index) => {
				return (
					<React.Fragment key={index}>
						<CalendarEventRow
							events={[event]}
							childEvents={[]}
							showCheckbox={false}
							showDetailIcon={true}
						/>
					</React.Fragment>
				)
			})}
        </HandlersContext.Provider>
    ) : (
		<Loader loading={isLoading} />
	);
};

export default CreateEventCalendarBody;
