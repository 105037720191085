import {
	preprocessCalendarSettingsData,
	processEventForUpload,
	preprocessResourcesData,
	preprocessFilterOptionsData,
	processEventsForUpload,
	preprocessEventsWithBranchItemsData,
	EventWithBranchItemType,
	preprocessTemplateData,
	TemplateDataType,
	preprocessResourceGroupsData,
	preprocessResourceSuppliersData,
	ResourceGroupsDataType,
	preprocessEventData,
	preprocessEventForPlanningData, EventForPlanningDataType, preprocessResourceItemsData,
} from "./dataProcessor";
import config from "../config.json";

import { CalendarEventType } from "../components/Calendar/CalendarEventRow";
import {ResourceType, SupplierType} from "../components/Calendar/CalendarResourceRow";
import { format } from "date-fns";
import { FiltersOptionsType } from "../redux/reducers/filterOptionsReducer";

import store from '../redux/store';
import {AppConfigType} from "../redux/reducers/appConfigReducer";
import {UserType} from "../redux/reducers/userReducer";
import { CalendarSettingsType } from "../redux/reducers/calendarReducer";

interface GetEventFiltersType {
    from: string;
    to: string;
    branchGroup?: string;
    branch?: string;
    eventGroup?: string;
    event?: string;
    resourceGroup?: string;
    resource?: string;
    unasignedResources?: string;
    search?: string;
    eventsWithoutOrder: boolean;
}

const url_patch = (path: string) => {
	if (path.startsWith('http')) {
		return new URL(path);
	}

	return new URL(path, document.baseURI);
}

const getHeaders = () => {
    return {
        "Content-Type": "application/json",
        "User-No": store.getState().user.userno || "2",
        "Calendar-Id": store.getState().filters.calendarId,
    }
};

const getHeadersAllCalendars = () => {
    return {
        "Content-Type": "application/json",
        "User-No": store.getState().user.userno || "2",
        "Calendar-Id": '-1',
    }
};

async function getEventFromAPI(eventId: string): Promise<CalendarEventType> {
	const url = url_patch(`${config.server.url}${config.server.path}/event/${eventId}`);
	return new Promise((resolve, reject) => {
		fetch(url.toString(), {
			method: "GET",
			headers: getHeaders(),
		})
			.then((response) => {
				return new Promise((resolve, reject) => {
					response.json().then((json) => {
						if (response.status === 200) {
							resolve({
								error: false,
								statusCode: response.status,
								body: json,
							});
						} else {
							resolve({
								error: true,
								statusCode: response.status,
								body: {},
							});
						}
					});
				});
			})
			.then((res: any) => {
				if (!res.error) {
					// console.log("api events", res.body.events);
					resolve(preprocessEventData(res.body.event)[0]);
				} else {
					reject(res.error);
				}
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

async function getEventsFromAPI(
    filters: GetEventFiltersType
): Promise<EventWithBranchItemType> {
    const filter = Object.entries(filters).filter(
        ([key, val]) => val !== undefined
    );
    const url = url_patch(`${config.server.url}${config.server.path}/events`);
    url.search = new URLSearchParams(Object.fromEntries(filter)).toString();
    return new Promise((resolve, reject) => {
        fetch(url.toString(), {
            method: "GET",
            headers: getHeadersAllCalendars(),
        })
            .then((response) => {
                return new Promise((resolve, reject) => {
                    response.json().then((json) => {
                        if (response.status === 200) {
                            resolve({
                                error: false,
                                statusCode: response.status,
                                body: json,
                            });
                        } else {
                            resolve({
                                error: true,
                                statusCode: response.status,
                                body: {},
                            });
                        }
                    });
                });
            })
            .then((res: any) => {
                if (!res.error) {
                    // console.log("api events", res.body.events);
                    resolve(preprocessEventsWithBranchItemsData(
                    	res.body.events || [],
						res.body.branchitems || []
					));
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
}

async function updateEventsFromAPI(events: any[]) {
    const url = url_patch(`${config.server.url}${config.server.path}/events`);
    return new Promise((resolve, reject) => {
        fetch(url.toString(), {
            method: "PUT",
            headers: getHeaders(),
            body: JSON.stringify({ events: processEventsForUpload(events) }),
        })
            .then((response) => {
                return new Promise((resolve, reject) => {
                    response.json().then((json) => {
                        if (response.status === 200) {
                            resolve({
                                error: false,
                                statusCode: response.status,
                                body: json,
                            });
                        } else {
                            resolve({
                                error: true,
                                statusCode: response.status,
                                body: {},
                            });
                        }
                    });
                });
            })
            .then((res: any) => {
                if (!res.error) {
                    resolve();
                } else {
                    console.log("err");
                    reject(res.error);
                }
            })
            .catch((err) => {
                console.log("err");
                reject(err);
            });
    });
}

async function updateEventFromAPI(event: any): Promise<CalendarEventType> {
	const url = url_patch(`${config.server.url}${config.server.path}/event/${event.id}`);
	// console.log(processEventForUpload(event));
	return new Promise((resolve, reject) => {
		fetch(url.toString(), {
			method: "PUT",
			headers: getHeaders(),
			body: JSON.stringify(processEventForUpload(event)),
		})
			.then((response) => {
				return new Promise((resolve, reject) => {
					response.json().then((json) => {
						if (response.status === 200) {
							resolve({
								error: false,
								statusCode: response.status,
								body: json,
							});
						} else {
							resolve({
								error: true,
								statusCode: response.status,
								body: {},
							});
						}
					});
				});
			})
			.then((res: any) => {
				if (!res.error) {
					resolve(res.body.event[0]);
				} else {
					console.log("err");
					reject(res.error);
				}
			})
			.catch((err) => {
				console.log("err");
				reject(err);
			});
	});
}

async function createTemplateFromAPI(
	event: CalendarEventType,
	templateName: string,
	hostNr: string,
	branchNr: string,
	billNr: string,
	): Promise<void> {
	const url = url_patch(
		`${config.server.url}${config.server.path}/templates`
	);

	return new Promise((resolve, reject) => {
		fetch(url.toString(), {
				method: "POST",
				headers: getHeaders(),
				body: JSON.stringify({ event: processEventForUpload(event), templateName, hostNr, branchNr, billNr }),
		})
				.then((response) => {
						return new Promise((resolve, reject) => {
								response.json().then((json) => {
										if (response.status === 200) {
												resolve({
														error: false,
														statusCode: response.status,
														body: json,
												});
										} else {
												resolve({
														error: true,
														statusCode: response.status,
														body: {},
												});
										}
								});
						});
				})
				.then((res: any) => {
						if (!res.error) {
								resolve();
						} else {
								reject(res.error);
						}
				})
				.catch((err) => {
						console.log(err);
						reject(err);
				});
	});
}

async function createEventFromAPI(event: CalendarEventType): Promise<CalendarEventType[]> {
    const url = url_patch(
        `${config.server.url}${config.server.path}/events`
    );
    return new Promise((resolve, reject) => {
        fetch(url.toString(), {
            method: "POST",
            headers: getHeaders(),
            body: JSON.stringify({ event: processEventForUpload(event) }),
        })
            .then((response) => {
                return new Promise((resolve, reject) => {
                    response.json().then((json) => {
                        if (response.status === 200) {
                            resolve({
                                error: false,
                                statusCode: response.status,
                                body: json,
                            });
                        } else {
                            resolve({
                                error: true,
                                statusCode: response.status,
                                body: {},
                            });
                        }
                    });
                });
            })
            .then((res: any) => {
                if (!res.error) {
                    resolve(preprocessEventData(res.body.event));
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
}

export interface CreateBillResponseType {
	success: boolean;
	status: number;
	data: string | {
		newbillnr: string;
		gotoaddress: string;
	}
}

async function createBillFromExternalAPI(
	storeManagerURL: string,
	eventId: string,
	templateId: string,
	user?: string,
	password?: string,
): Promise<CreateBillResponseType> {
	const params = new URLSearchParams({
		operation: 'finalizeeventcreation',
		eventnr: eventId,
		templatenr: templateId,
		// user: 'APIUser16',
		// password: '8GJBcqkvkAhsnhFs',
	});
	const url = new URL(`${storeManagerURL}${config.external.createBillPath}`);
	url.search = params.toString();
	return new Promise((resolve, reject) => {
		fetch(url.toString(), {
			method: "GET",
			credentials: "include",
		})
			.then((response) => {
				return new Promise((resolve, reject) => {
					response.json().then((json) => {
						if (response.status === 200) {
							resolve({
								error: false,
								statusCode: response.status,
								body: json,
							});
						} else {
							resolve({
								error: true,
								statusCode: response.status,
								body: {},
							});
						}
					});
				});
			})
			.then((res: any) => {
				if (!res.error) {
					resolve(res.body);
				} else {
					reject(res.error);
				}
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

async function sendEmailFromExternalAPI(
	storeManagerURL: string,
	eventId: string,
	user?: string,
	password?: string,
): Promise<CreateBillResponseType> {
	const params = new URLSearchParams({
		operation: 'sendresourcemail',
		eventnr: eventId,
		// user: 'APIUser16',
		// password: '8GJBcqkvkAhsnhFs',
	});
	const url = new URL(`${storeManagerURL}${config.external.createBillPath}`);
	url.search = params.toString();
	return new Promise((resolve, reject) => {
		fetch(url.toString(), {
			method: "GET",
			credentials: "include",
		})
			.then((response) => {
				return new Promise((resolve, reject) => {
					response.json().then((json) => {
						if (response.status === 200) {
							resolve({
								error: false,
								statusCode: response.status,
								body: json,
							});
						} else {
							resolve({
								error: true,
								statusCode: response.status,
								body: {},
							});
						}
					});
				});
			})
			.then((res: any) => {
				if (!res.error) {
					console.log(res.body);
					resolve(res.body);
				} else {
					reject(res.error);
				}
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export interface LockEventResponseType {
	success: boolean;
	status: number;
	data: {
		Type: string;
		Status: string;
        UserNr: string;
        OrderNr: string;
        OldUserNr: string;
        OldStatus: string;
	}
}

async function lockEventAndGetInfoFromExternalAPI(
	storeManagerURL: string,
	eventId: string,
): Promise<LockEventResponseType> {
	const params = new URLSearchParams({
		operation: 'geteventorderinfo',
		eventnr: eventId,
        autoopen: '',
	});
	const url = new URL(`${storeManagerURL}${config.external.createBillPath}`);
	url.search = params.toString();
	return new Promise((resolve, reject) => {
		fetch(url.toString(), {
			method: "GET",
			credentials: "include",
		})
			.then((response) => {
				return new Promise((resolve, reject) => {
					response.json().then((json) => {
						if (response.status === 200) {
							resolve({
								error: false,
								statusCode: response.status,
								body: json,
							});
						} else {
							resolve({
								error: true,
								statusCode: response.status,
								body: {},
							});
						}
					});
				});
			})
			.then((res: any) => {
				if (!res.error) {
					console.log(res.body);
					resolve(res.body);
				} else {
					reject(res.error);
				}
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

async function unlockEventFromExternalAPI(
	storeManagerURL: string,
	eventId: string,
	eventStatus: string,
	userId: string,
): Promise<any> {
	const params = new URLSearchParams({
		operation: 'reset',
		eventnr: eventId,
		userNr: userId,
        status: eventStatus,
	});
	const url = new URL(`${storeManagerURL}${config.external.createBillPath}`);
	url.search = params.toString();
	return new Promise((resolve, reject) => {
		fetch(url.toString(), {
			method: "GET",
			credentials: "include",
		})
			.then((response) => {
				return new Promise((resolve, reject) => {
					response.text().then((json) => {
						if (response.status === 200) {
							resolve({
								error: false,
								statusCode: response.status,
								body: json,
							});
						} else {
							resolve({
								error: true,
								statusCode: response.status,
								body: {},
							});
						}
					});
				});
			})
			.then((res: any) => {
				if (!res.error) {
					console.log(res.body);
					resolve(res.body);
				} else {
					reject(res.error);
				}
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export interface GetBillResponseType {
	success: boolean;
	status: number;
	data: string;
}

async function getBillLinkFromExternalAPI(
	storeManagerURL: string,
	billnr: string,
): Promise<GetBillResponseType> {
	const params = new URLSearchParams({
		operation: 'getbilllink',
		billnr: billnr,
	});
	const url = new URL(`${storeManagerURL}${config.external.createBillPath}`);
	url.search = params.toString();
	return new Promise((resolve, reject) => {
		fetch(url.toString(), {
			method: "GET",
			credentials: "include",
		})
			.then((response) => {
				return new Promise((resolve, reject) => {
					response.json().then((json) => {
						if (response.status === 200) {
							resolve({
								error: false,
								statusCode: response.status,
								body: json,
							});
						} else {
							resolve({
								error: true,
								statusCode: response.status,
								body: {},
							});
						}
					});
				});
			})
			.then((res: any) => {
				if (!res.error) {
					console.log(res.body);
					resolve(res.body);
				} else {
					reject(res.error);
				}
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

async function loadUserFromExternalAPI(storeManagerURL: string): Promise<UserType> {
	const url = new URL(`${storeManagerURL}${config.external.loginInfoPath}`);
	return new Promise((resolve, reject) => {
		fetch(url.toString(), {
			method: "GET",
			credentials: "include",
		})
			.then((response) => {
				return new Promise((resolve, reject) => {
					response.json().then((json) => {
						if (response.status === 200) {
							resolve({
								error: false,
								statusCode: response.status,
								body: json,
							});
						} else {
							resolve({
								error: true,
								statusCode: response.status,
								body: {},
							});
						}
					});
				});
			})
			.then((res: any) => {
				if (!res.error) {
					resolve(res.body as UserType);
				} else {
					reject(res.error);
				}
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

async function deleteEventFromExternalAPI(
	storeManagerURL: string,
	eventIds: string,
): Promise<any> {
	const params = new URLSearchParams({
		operation: 'deleteevent',
		eventnrs: eventIds,
	});
	const url = new URL(`${storeManagerURL}${config.external.createBillPath}`);
	url.search = params.toString();
	return new Promise((resolve, reject) => {
		fetch(url.toString(), {
			method: "GET",
			credentials: "include",
		})
			.then((response) => {
				return new Promise((resolve, reject) => {
					response.text().then((json) => {
						if (response.status === 200) {
							resolve({
								error: false,
								statusCode: response.status,
								body: json,
							});
						} else {
							resolve({
								error: true,
								statusCode: response.status,
								body: {},
							});
						}
					});
				});
			})
			.then((res: any) => {
				if (!res.error) {
					console.log(res.body);
					resolve(res.body);
				} else {
					reject(res.error);
				}
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

async function mergeEventsFromAPI(eventIds: string[]) {
    const url = url_patch(
        `${config.server.url}${config.server.path}/events/merge`
    );
    return new Promise((resolve, reject) => {
        fetch(url.toString(), {
            method: "PUT",
            headers: getHeaders(),
            body: JSON.stringify({ events: eventIds }),
        })
            .then((response) => {
                return new Promise((resolve, reject) => {
                    response.json().then((json) => {
                        if (response.status === 200) {
                            resolve({
                                error: false,
                                statusCode: response.status,
                                body: json,
                            });
                        } else {
                            resolve({
                                error: true,
                                statusCode: response.status,
                                body: {},
                            });
                        }
                    });
                });
            })
            .then((res: any) => {
                if (!res.error) {
                    resolve();
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
}

async function deleteEventsFromAPI(eventIds: string[]) {
    const url = url_patch(`${config.server.url}${config.server.path}/events`);
    const events = eventIds.map((eid) => {
        return { EventNr: eid };
    });
    return new Promise((resolve, reject) => {
        fetch(url.toString(), {
            method: "DELETE",
            headers: getHeaders(),
            body: JSON.stringify({ events }),
        })
            .then((response) => {
                return new Promise((resolve, reject) => {
                    response.json().then((json) => {
                        if (response.status === 200) {
                            resolve({
                                error: false,
                                statusCode: response.status,
                                body: json,
                            });
                        } else {
                            resolve({
                                error: true,
                                statusCode: response.status,
                                body: {},
                            });
                        }
                    });
                });
            })
            .then((res: any) => {
                if (!res.error) {
                    resolve();
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
}

async function getTemplateFromAPI(
	billNr: string,
	branchNr: string,
	hostNr: string,
	from: Date,
	to: Date,
	calendarId: string): Promise<TemplateDataType>
{
	const params = new URLSearchParams({
		billnr: billNr,
		branchnr: branchNr,
		hostnr: hostNr,
		from: format(from, "yyyy-MM-dd"),
		to: format(to, "yyyy-MM-dd"),
		calendarId,
	});
    const url = url_patch(
        `${config.server.url}${config.server.path}/template`
    );
	url.search = params.toString();
    return new Promise((resolve, reject) => {
        fetch(url.toString(), {
            method: "GET",
            headers: getHeaders(),
        })
            .then((response) => {
                return new Promise((resolve, reject) => {
                    response.json().then((json) => {
                        if (response.status === 200) {
                            resolve({
                                error: false,
                                statusCode: response.status,
                                body: json,
                            });
                        } else {
                            resolve({
                                error: true,
                                statusCode: response.status,
                                body: {},
                            });
                        }
                    });
                });
            })
            .then((res: any) => {
                if (!res.error) {
                    resolve(preprocessTemplateData(res.body));
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
}

async function getEventForPlanningFromAPI(
	eventNr: string,
	from: Date,
	to: Date,
	calendarId: string,
	branchId: string | null): Promise<EventForPlanningDataType>
{
	const params = new URLSearchParams({
		EventNr: eventNr,
		from: format(from, "yyyy-MM-dd"),
		to: format(to, "yyyy-MM-dd"),
		calendarId,
		BranchNr: branchId ?? '',
	});
	const url = url_patch(
		`${config.server.url}${config.server.path}/planning/event`
	);
	url.search = params.toString();
	return new Promise((resolve, reject) => {
		fetch(url.toString(), {
			method: "GET",
			headers: getHeaders(),
		})
			.then((response) => {
				return new Promise((resolve, reject) => {
					response.json().then((json) => {
						if (response.status === 200) {
							resolve({
								error: false,
								statusCode: response.status,
								body: json,
							});
						} else {
							resolve({
								error: true,
								statusCode: response.status,
								body: {},
							});
						}
					});
				});
			})
			.then((res: any) => {
				if (!res.error) {
					console.log(res.body);
					resolve(preprocessEventForPlanningData(res.body));
				} else {
					reject(res.error);
				}
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

async function getEventResourcesOptionsFromAPI(eventId: string): Promise<ResourceGroupsDataType>
{
	const url = url_patch(
		`${config.server.url}${config.server.path}/events/${eventId}/resources`
	);
	return new Promise((resolve, reject) => {
		fetch(url.toString(), {
			method: "GET",
			headers: getHeaders(),
		})
			.then((response) => {
				return new Promise((resolve, reject) => {
					response.json().then((json) => {
						if (response.status === 200) {
							resolve({
								error: false,
								statusCode: response.status,
								body: json,
							});
						} else {
							resolve({
								error: true,
								statusCode: response.status,
								body: {},
							});
						}
					});
				});
			})
			.then((res: any) => {
				if (!res.error) {
					resolve(preprocessResourceGroupsData(res.body));
				} else {
					reject(res.error);
				}
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

async function getResourceAddressOptionsFromAPI(itemNr: string, branchNr: string): Promise<SupplierType[]>
{
	const params = new URLSearchParams({
		ItemNr: itemNr,
		BranchNr: branchNr,
	});
	const url = url_patch(
		`${config.server.url}${config.server.path}/resource/addresses`
	);
	url.search = params.toString();
	return new Promise((resolve, reject) => {
		fetch(url.toString(), {
			method: "GET",
			headers: getHeaders(),
		})
			.then((response) => {
				return new Promise((resolve, reject) => {
					response.json().then((json) => {
						if (response.status === 200) {
							resolve({
								error: false,
								statusCode: response.status,
								body: json,
							});
						} else {
							resolve({
								error: true,
								statusCode: response.status,
								body: {},
							});
						}
					});
				});
			})
			.then((res: any) => {
				if (!res.error) {
					resolve(preprocessResourceSuppliersData(res.body.addresses));
				} else {
					reject(res.error);
				}
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

async function getResourcesFromAPI(filters: {
    from: Date;
    to: Date;
    ids: string[];
}): Promise<ResourceType[]> {
    const url = url_patch(`${config.server.url}${config.server.path}/resources`);
    return new Promise((resolve, reject) => {
        fetch(url.toString(), {
            method: "PUT",
            headers: getHeaders(),
            body: JSON.stringify({
                from: format(filters.from, "yyyy-MM-dd"),
                to: format(filters.to, "yyyy-MM-dd"),
                resourceIds: filters.ids,
            }),
        })
            .then((response) => {
                return new Promise((resolve, reject) => {
                    response.json().then((json) => {
                        if (response.status === 200) {
                            resolve({
                                error: false,
                                statusCode: response.status,
                                body: json,
                            });
                        } else {
                            resolve({
                                error: true,
                                statusCode: response.status,
                                body: {},
                            });
                        }
                    });
                });
            })
            .then((res: any) => {
                if (!res.error) {
                    // console.log("resss ", res.body);
                    resolve(preprocessResourcesData(res.body.resources || []));
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
}

export interface ResourceItemType {
	label: string;
	itemId: string;
	actualPrice: number;
	itemName: string;
	fullStock: number;
	controlSequence: string;
	itemTypeNr: string;
	unitNr: string;
}

async function getBranchResourcesFromAPI(branchNr: string): Promise<ResourceItemType[]> {
	const params = new URLSearchParams({
		BranchNr: branchNr,
	});
    const url = url_patch(`${config.server.url}${config.server.path}/resourceItems`);
	url.search = params.toString();
    return new Promise((resolve, reject) => {
        fetch(url.toString(), {
            method: "GET",
            headers: getHeaders(),
        })
            .then((response) => {
                return new Promise((resolve, reject) => {
                    response.json().then((json) => {
                        if (response.status === 200) {
                            resolve({
                                error: false,
                                statusCode: response.status,
                                body: json,
                            });
                        } else {
                            resolve({
                                error: true,
                                statusCode: response.status,
                                body: {},
                            });
                        }
                    });
                });
            })
            .then((res: any) => {
                if (!res.error) {
                    // console.log("resss ", res.body);
                    resolve(preprocessResourceItemsData(res.body.resourceItems || []));
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
}

async function getFilterOptionsFromAPI(): Promise<FiltersOptionsType> {
    const url = url_patch(`${config.server.url}${config.server.path}/filters`);
    return new Promise((resolve, reject) => {
        fetch(url.toString(), {
            method: "GET",
            headers: getHeaders(),
        })
            .then((response) => {
                return new Promise((resolve, reject) => {
                    response.json().then((json) => {
                        if (response.status === 200) {
                            resolve({
                                error: false,
                                statusCode: response.status,
                                body: json,
                            });
                        } else {
                            resolve({
                                error: true,
                                statusCode: response.status,
                                body: {},
                            });
                        }
                    });
                });
            })
            .then((res: any) => {
                if (!res.error) {
                    // console.log("filters api", res.body);
                    resolve(preprocessFilterOptionsData(res.body));
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
}

async function getAppConfigFromAPI(): Promise<AppConfigType> {
	const url = url_patch(`${config.server.url}${config.server.path}/config`);
	return new Promise((resolve, reject) => {
		fetch(url.toString(), {
			method: "GET",
			headers: getHeaders(),
		})
			.then((response) => {
				return new Promise((resolve, reject) => {
					response.json().then((json) => {
						if (response.status === 200) {
							resolve({
								error: false,
								statusCode: response.status,
								body: json,
							});
						} else {
							resolve({
								error: true,
								statusCode: response.status,
								body: {},
							});
						}
					});
				});
			})
			.then((res: any) => {
				if (!res.error) {
					resolve(res.body as AppConfigType);
				} else {
					reject(res.error);
				}
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

async function getCalendarSettingsFromAPI(): Promise<CalendarSettingsType> {
    const url = url_patch(
        `${config.server.url}${config.server.path}/calendar/settings`
    );
    return new Promise((resolve, reject) => {
        fetch(url.toString(), {
            method: "GET",
            headers: getHeaders(),
        })
            .then((response) => {
                return new Promise((resolve, reject) => {
                    response.json().then((json) => {
                        if (response.status === 200) {
                            resolve({
                                error: false,
                                statusCode: response.status,
                                body: json,
                            });
                        } else {
                            resolve({
                                error: true,
                                statusCode: response.status,
                                body: {},
                            });
                        }
                    });
                });
            })
            .then((res: any) => {
                if (!res.error) {
                    // console.log("calendar settings", res.body);
                    resolve(preprocessCalendarSettingsData(res.body));
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
}

export interface TranslationType {
	[key: string]: {
		LanguageNr: string;
		ISOCode: string;
		LanguageName: string;
		messages: {
			[key: string]: string;
		}
	};
}

async function getCalendarTranslationsFromAPI(): Promise<TranslationType> {
    const url = url_patch(
        `${config.server.url}${config.server.path}/translations`
    );
    return new Promise((resolve, reject) => {
        fetch(url.toString(), {
            method: "GET",
            headers: getHeaders(),
        })
            .then((response) => {
                return new Promise((resolve, reject) => {
                    response.json().then((json) => {
                        if (response.status === 200) {
                            resolve({
                                error: false,
                                statusCode: response.status,
                                body: json,
                            });
                        } else {
                            resolve({
                                error: true,
                                statusCode: response.status,
                                body: {},
                            });
                        }
                    });
                });
            })
            .then((res: any) => {
                if (!res.error) {
                    // console.log("calendar info", res.body);
					resolve(res.body as TranslationType);
                    // resolve(preprocessCalendarSettingsData(res.body));
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
}

export {
	getEventFromAPI,
	getEventsFromAPI,
	updateEventsFromAPI,
	updateEventFromAPI,
	createTemplateFromAPI,
	createEventFromAPI,
	mergeEventsFromAPI,
	deleteEventsFromAPI,
	getTemplateFromAPI,
	getEventForPlanningFromAPI,
	sendEmailFromExternalAPI,
	getResourcesFromAPI,
	getFilterOptionsFromAPI,
	getCalendarSettingsFromAPI,
	getEventResourcesOptionsFromAPI,
	getResourceAddressOptionsFromAPI,
	getBranchResourcesFromAPI,
	createBillFromExternalAPI,
	getAppConfigFromAPI,
	loadUserFromExternalAPI,
	getCalendarTranslationsFromAPI,
	lockEventAndGetInfoFromExternalAPI,
	unlockEventFromExternalAPI,
	getBillLinkFromExternalAPI,
	deleteEventFromExternalAPI,
};
