import {addDays, addMinutes, differenceInCalendarDays, differenceInMinutes, format,} from "date-fns";

import {CalendarDisplayType, WorkTime} from "../components/Calendar/Calendar";
import {CalendarEventType} from "../components/Calendar/CalendarEventRow";
import {ResourceType, SupplierAvailabilityType} from "../components/Calendar/CalendarResourceRow";
import {TimeLineValue} from "../components/Calendar/TimeLine";
import config from "../config.json";
import {getOverlappingMinutesInterval} from "./dateHelpers";
import moment from "moment";
import {groupBy, orderBy, uniq} from "lodash";
import {BranchItemType} from "./dataProcessor";
import {eventColorsByStatus} from "../enums";
import { CalendarSettingsType, EventSortOptionType } from "../redux/reducers/calendarReducer";

function searchForArray(haystack: any[], needle: any[]) {
	if (!haystack.length) return -1;
	let i, j, current;
	for(i = 0; i < haystack.length; ++i){
		if(needle.length === haystack[i].length){
			current = haystack[i];
			for(j = 0; j < needle.length && needle[j] === current[j]; ++j);
			if(j === needle.length)
				return i;
		}
	}
	return -1;
}

// TODO Refactor
const checkWhichResourcesOverlap = (rId: string, resources: ResourceType[]): [string[]] => {
	// console.log(rId);
	const overlaps: any = [[rId]];
	resources.forEach(r1 => {
		const overlap = [r1.id];
		const r1StartDate = moment(r1.startDate);
		const r1EndDate = moment(r1.endDate);
		resources.forEach(r2 => {
			if (r1.id === r2.id) return;
			const r2StartDate = moment(r2.startDate);
			const r2EndDate = moment(r2.endDate);
			if ((r1StartDate <= r2EndDate) && (r2StartDate <= r1EndDate)) {
				overlap.push(r2.id);
			}
		});
		// if (overlap.length > 1) {
		overlap.push(rId);
		overlaps.push(overlap);
		// }
	});

	return overlaps;
};

export const findOverlappingEvents = (events: CalendarEventType[], branchItems: BranchItemType[]): string[] => {
	// const overlaps: any = [];
	let eventResources: ResourceType[] = events.reduce((acc, e) => {
		return [...acc, ...e.resources];
	}, [] as ResourceType[]);

	eventResources = eventResources.filter((r) => r.status && r.controlSequence !== 'T');

	const grouppedResourcesByBranchItem = groupBy(eventResources, 'itemId');
	// console.log(grouppedResourcesByBranchItem);
	const grouppedResourcesByBranchItemAndTimeSlot: any = {};

	Object.keys(grouppedResourcesByBranchItem).forEach(function(branchItem,idx) {
		const resources = grouppedResourcesByBranchItem[branchItem];
		const overlaps: any = [];
		resources.forEach(r1 => {
			const overlap = [r1.id];
			const r1StartDate = moment(r1.startDate);
			const r1EndDate = moment(r1.endDate);
			resources.forEach(r2 => {
				if (r1.id === r2.id) return;
				const r2StartDate = moment(r2.startDate);
				const r2EndDate = moment(r2.endDate);
				if ((r1StartDate < r2EndDate) && (r2StartDate < r1EndDate)) {
					overlap.push(r2.id);
				}
			});

			const res = eventResources.filter(r => overlap.includes(r.id) && r1.id !== r.id);
			const filtered = checkWhichResourcesOverlap(r1.id, res);
			filtered.forEach(group => {
				const sorted = group.sort();
				if (searchForArray(overlaps, sorted) == -1) {
					overlaps.push(sorted);
				}
			});
			// const sorted = overlap.sort();
			// if (searchForArray(overlaps, sorted) == -1) {
			// 	overlaps.push(sorted);
			// }
		});
		grouppedResourcesByBranchItemAndTimeSlot[branchItem] = overlaps;
	});

	// console.log(grouppedResourcesByBranchItemAndTimeSlot);

	const problematic: string[] = [];
	branchItems.forEach(brItem => {
		if (brItem.itemNr.toString() in grouppedResourcesByBranchItemAndTimeSlot) {
			const groups = grouppedResourcesByBranchItemAndTimeSlot[brItem.itemNr.toString()];
			groups.forEach((group: string[]) => {
				const resources = eventResources.filter(r => group.includes(r.id));
				const qtySum = resources.reduce((acc, res) => acc + (res.currentStock || 0), 0);
				// console.log(brItem.itemNr, qtySum, brItem.fullStoreQty, qtySum > brItem.fullStoreQty);
				if (qtySum > brItem.fullStoreQty) {
					problematic.push(...group);
				}
			})
		}
	});

	// console.log(uniq(problematic));
	return uniq(problematic);



	// events.forEach(e1 => {
	// 	const overlap = [e1.id]
	// 	const e1StartDate = moment(e1.startDate);
	// 	const e1EndDate = moment(e1.endDate);
	//
	// 	events.forEach(e2 => {
	// 		if (e1.id === e2.id) return;
	//
	// 		const e2StartDate = moment(e2.startDate);
	// 		const e2EndDate = moment(e2.endDate);
	// 		if ((e1StartDate <= e2EndDate) && (e2StartDate <= e1EndDate)) {
	// 			overlap.push(e2.id);
	// 		}
	// 	});
	//
	// 	if (overlap.length > 1) {
	// 		const sorted = overlap.sort();
	// 		if (searchForArray(overlaps, sorted) == -1) {
	// 			overlaps.push(sorted);
	// 		}
	// 	}
	// });
	//
	// console.log(overlaps);
}

// export const calcAvailableEventResourceQty = (event: CalendarEventType, events: CalendarEventType[], branchItems: BranchItemType[]): any => {
// 	const eventResources = event.resources;
// 	const allResources = events.flatMap((e) => e.resources);
//
// 	const result = {};
//
// 	eventResources.forEach((r) => {
// 		const r1StartDate = moment(r.startDate);
// 		const r1EndDate = moment(r.endDate);
// 		const similarResources = allResources.map((sr) => {
// 			const r2StartDate = moment(sr.startDate);
// 			const r2EndDate = moment(sr.endDate);
// 			if (!((r1StartDate <= r2EndDate) && (r2StartDate <= r1EndDate))) {
// 				return false;
// 			}
// 			return sr.itemId === r.itemId && sr.id !== r.id;
// 		});
//
// 		console.log(`For resource ${r.id} found similar resources: ${similarResources.join(', ')}`)
// 	});
// }

export interface AvailableResourceQtyType {
	[key: string]: {
		max: number;
		used: number;
		usedExeptMe: number;
		maxICanUse: number;
		maxIWant: number;
		available: number;
	}
}

export const calcAvailableResourcesQty = (events: CalendarEventType[], branchItems: BranchItemType[]): AvailableResourceQtyType => {
	const allResources = events.flatMap((e) => e.resources)
								.filter((r) => r.status);
	const grouppedResourcesByBranchId = groupBy(allResources, 'itemId');
	const result: AvailableResourceQtyType = {};

	for (const [itemId, resources] of Object.entries(grouppedResourcesByBranchId)) {
		const branchItem = branchItems.find((bi) => bi.itemNr === itemId);
		resources.forEach((r1) => {
			const overlaps = [r1];
			const r1StartDate = moment(r1.startDate);
			const r1EndDate = moment(r1.endDate);
			resources.forEach(r2 => {
				if (r1.id === r2.id) return;
				const r2StartDate = moment(r2.startDate);
				const r2EndDate = moment(r2.endDate);
				if ((r1StartDate <= r2EndDate) && (r2StartDate <= r1EndDate)) {
					overlaps.push(r2);
				}
			});

			const qtySum = overlaps.reduce((acc, res) => acc + (res.currentStock || 0), 0);
			overlaps.shift();
			const qtySumExceptMe = overlaps.reduce((acc, res) => acc + (res.currentStock || 0), 0);
			const branchMax = branchItem?.fullStoreQty || 0;
			const resourceFullStock = r1?.fullStock || 0;
			result[r1.id] = {
				max: branchMax,
				used: qtySum,
				usedExeptMe: qtySumExceptMe,
				maxICanUse: branchMax - qtySumExceptMe,
				maxIWant: branchMax - qtySumExceptMe > resourceFullStock ? resourceFullStock : Math.max(0, branchMax - qtySumExceptMe),
				available: branchMax - qtySum < 0 ? 0: branchMax - qtySum
			}
		});
	}

	return result;

	// eventResources.forEach((r) => {
	// 	const r1StartDate = moment(r.startDate);
	// 	const r1EndDate = moment(r.endDate);
	// 	const similarResources = allResources.map((sr) => {
	// 		const r2StartDate = moment(sr.startDate);
	// 		const r2EndDate = moment(sr.endDate);
	// 		if (!((r1StartDate <= r2EndDate) && (r2StartDate <= r1EndDate))) {
	// 			return false;
	// 		}
	// 		return sr.itemId === r.itemId && sr.id !== r.id;
	// 	});
	//
	// 	console.log(`For resource ${r.id} found similar resources: ${similarResources.join(', ')}`)
	// });
}

// export const needsToRecalculateResourceQty = (qtys: AvailableResourceQtyType): boolean => {
// 	const result = false;
//
// 	for (const [resourceId, obj] of Object.entries(qtys)) {
// 		if (obj.)
// 	}
//
// 	return result;
// }

export const calcVirtualEventResourcesQty = (events: CalendarEventType[], branchItems: BranchItemType[], conflicts: string[]): any => {
	const result: { [id: string]: number } = {};
	const virtualResources = conflicts.filter((c) => c.startsWith('evirtual'));
	const eventResources: ResourceType[] = events.flatMap((e) => e.resources);

	virtualResources.forEach((vres) => {
		const branchItemId = vres.split('-')[2].replace('bri', '');
		const branchItem = branchItems.find((bi) => bi.itemNr === branchItemId);
		const conflictedResourcesIds = conflicts.filter((c) => c.endsWith(branchItemId) && !c.startsWith('evirtual'));
		const conflictedResources = eventResources.filter((r) => conflictedResourcesIds.includes(r.id));
		const virtualResource = eventResources.find((r) =>r.id === vres);
		const usedQty = conflictedResources.reduce((acc, res) => acc + (res.currentStock || 0), 0);
		const availableQty = (branchItem?.fullStoreQty || 0) - usedQty;
		const virtualResourceCurrentStock = virtualResource?.currentStock || 0;
		result[vres] = availableQty < 0 ? 0 : (availableQty > virtualResourceCurrentStock ? virtualResourceCurrentStock : availableQty);
	});

	return result;
}

// calculates new start and end date of given resource
function calcResourceStartEndDates(
    deltaX: number,
    resource: ResourceType | null,
    calendarDisplayType: CalendarDisplayType,
    workTimeStart: WorkTime,
    workTimeEnd: WorkTime,
    daysInMonth: number,
    opts?: { updateStartDate: boolean; updateEndDate: boolean }
) {
    if (!resource || !resource.startDate || !resource.endDate) {
        return null;
    }
    const mltp = deltaX > 0 ? 1 : -1;
    const stepCount =
        Math.floor(
            Math.abs(
                Math.floor(deltaX) /
                    Math.floor(
                        getTimeStepInPx(
                            calendarDisplayType,
                            workTimeStart,
                            workTimeEnd,
                            daysInMonth
                        )
                    )
            )
        ) * mltp;
    if (!stepCount || stepCount === 0) {
        return null;
    }
    const stepInMinutes =
        stepCount * config.calendar[calendarDisplayType].timeStepInMinutes;
    let newStartDate =
        !opts || opts.updateStartDate
            ? addMinutes(resource.startDate, stepInMinutes)
            : resource.startDate;
    let newEndDate =
        !opts || opts.updateEndDate
            ? addMinutes(resource.endDate, stepInMinutes)
            : resource.endDate;

    if (deltaX < 0) {
        let startDayNonWorkingMins = getNonWorkingMinutesInRange(
            newStartDate,
            resource.startDate,
            workTimeStart,
            workTimeEnd
        );
        let endDayNonWorkingMins = getNonWorkingMinutesInRange(
            newEndDate,
            resource.endDate,
            workTimeStart,
            workTimeEnd
        );
        while (startDayNonWorkingMins !== 0) {
            let prevStartDate = newStartDate;
            newStartDate = addMinutes(
                prevStartDate,
                startDayNonWorkingMins * -1
            );
            startDayNonWorkingMins = getNonWorkingMinutesInRange(
                newStartDate,
                prevStartDate,
                workTimeStart,
                workTimeEnd
            );
        }
        while (endDayNonWorkingMins !== 0) {
            let prevEndDate = newEndDate;
            newEndDate = addMinutes(prevEndDate, endDayNonWorkingMins * -1);
            endDayNonWorkingMins = getNonWorkingMinutesInRange(
                newEndDate,
                prevEndDate,
                workTimeStart,
                workTimeEnd
            );
        }
    } else if (deltaX > 0) {
        let startDayNonWorkingMins = getNonWorkingMinutesInRange(
            resource.startDate,
            newStartDate,
            workTimeStart,
            workTimeEnd
        );
        let endDayNonWorkingMins = getNonWorkingMinutesInRange(
            resource.endDate,
            newEndDate,
            workTimeStart,
            workTimeEnd
        );
        while (startDayNonWorkingMins !== 0) {
            let prevStartDate = newStartDate;
            newStartDate = addMinutes(prevStartDate, startDayNonWorkingMins);
            startDayNonWorkingMins = getNonWorkingMinutesInRange(
                prevStartDate,
                newStartDate,
                workTimeStart,
                workTimeEnd
            );
        }
        while (endDayNonWorkingMins !== 0) {
            let prevEndDate = newEndDate;
            newEndDate = addMinutes(prevEndDate, endDayNonWorkingMins);
            endDayNonWorkingMins = getNonWorkingMinutesInRange(
                prevEndDate,
                newEndDate,
                workTimeStart,
                workTimeEnd
            );
        }
    }

    if (
        newStartDate &&
        newEndDate &&
        newStartDate.getTime() < newEndDate.getTime()
    ) {
        return {
            resourceUpdate: {
                resourceId: resource.id,
                startDate: newStartDate,
                endDate: newEndDate,
            },
            stepCount,
        };
    }
    return null;
}

// calculates new start and end date of given event and their resources (if opts are set to)
function calcEventStartEndDates(
    deltaX: number,
    event: CalendarEventType | null,
    calendarDisplayType: CalendarDisplayType,
    workTimeStart: WorkTime,
    workTimeEnd: WorkTime,
    daysInMonth: number,
    opts?: { updateStartDate: boolean; updateEndDate: boolean }
) {
    if (!event || !event.startDate || !event.endDate) {
        return null;
    }
    const mltp = deltaX > 0 ? 1 : -1;
    const stepCount =
        Math.floor(
            Math.abs(
                Math.floor(deltaX) /
                    Math.floor(
                        getTimeStepInPx(
                            calendarDisplayType,
                            workTimeStart,
                            workTimeEnd,
                            daysInMonth
                        )
                    )
            )
        ) * mltp;
    if (!stepCount || stepCount === 0) {
        return null;
    }
    const stepInMinutes =
        stepCount * config.calendar[calendarDisplayType].timeStepInMinutes;
    let newStartDate =
        !opts || opts.updateStartDate
            ? addMinutes(event.startDate, stepInMinutes)
            : event.startDate;
    let newEndDate =
        !opts || opts.updateEndDate
            ? addMinutes(event.endDate, stepInMinutes)
            : event.endDate;

    if (deltaX < 0) {
        let startDayNonWorkingMins = getNonWorkingMinutesInRange(
            newStartDate,
            event.startDate,
            workTimeStart,
            workTimeEnd
        );
        let endDayNonWorkingMins = getNonWorkingMinutesInRange(
            newEndDate,
            event.endDate,
            workTimeStart,
            workTimeEnd
        );
        while (startDayNonWorkingMins !== 0) {
            let prevStartDate = newStartDate;
            newStartDate = addMinutes(
                prevStartDate,
                startDayNonWorkingMins * -1
            );
            startDayNonWorkingMins = getNonWorkingMinutesInRange(
                newStartDate,
                prevStartDate,
                workTimeStart,
                workTimeEnd
            );
        }
        while (endDayNonWorkingMins !== 0) {
            let prevEndDate = newEndDate;
            newEndDate = addMinutes(prevEndDate, endDayNonWorkingMins * -1);
            endDayNonWorkingMins = getNonWorkingMinutesInRange(
                newEndDate,
                prevEndDate,
                workTimeStart,
                workTimeEnd
            );
        }
    } else if (deltaX > 0) {
        let startDayNonWorkingMins = getNonWorkingMinutesInRange(
            event.startDate,
            newStartDate,
            workTimeStart,
            workTimeEnd
        );
        let endDayNonWorkingMins = getNonWorkingMinutesInRange(
            event.endDate,
            newEndDate,
            workTimeStart,
            workTimeEnd
        );
        while (startDayNonWorkingMins !== 0) {
            let prevStartDate = newStartDate;
            newStartDate = addMinutes(prevStartDate, startDayNonWorkingMins);
            startDayNonWorkingMins = getNonWorkingMinutesInRange(
                prevStartDate,
                newStartDate,
                workTimeStart,
                workTimeEnd
            );
        }
        while (endDayNonWorkingMins !== 0) {
            let prevEndDate = newEndDate;
            newEndDate = addMinutes(prevEndDate, endDayNonWorkingMins);
            endDayNonWorkingMins = getNonWorkingMinutesInRange(
                prevEndDate,
                newEndDate,
                workTimeStart,
                workTimeEnd
            );
        }
    }

    if (
        newStartDate &&
        newEndDate &&
        newStartDate.getTime() < newEndDate.getTime()
    ) {
        let updates: {
            resourceId: string;
            startDate: Date;
            endDate: Date;
        }[] = [];
        if (!opts || (opts.updateStartDate && opts.updateEndDate)) {
            event.resources.forEach((resource) => {
                const update = calcResourceStartEndDates(
                    deltaX,
                    resource,
                    calendarDisplayType,
                    workTimeStart,
                    workTimeEnd,
                    daysInMonth
                );
                if (update) {
                    updates = [...updates, update.resourceUpdate];
                }
            });
        }
        return {
            eventUpdate: {
                eventId: event.id,
                startDate: newStartDate,
                endDate: newEndDate,
            },
            resourcesUpdates: updates,
            stepCount,
        };
    }
    return null;
}

function getTimeLineValues(
    startTime: WorkTime,
    endTime: WorkTime,
    type: CalendarDisplayType,
    monthDays?: number
) {
    let timeLineValues: TimeLineValue[] = [];
    const showHalfHours = type === "day" ? true : false;
    const timeFormat = showHalfHours ? "H:mm" : "H";
    const stratTimeDate = new Date(
        new Date(0).setHours(startTime.hours, startTime.minutes)
    );
    const endTimeDate = new Date(
        new Date(0).setHours(endTime.hours, endTime.minutes)
    );
    const rangeTimeDiffInMin = differenceInMinutes(endTimeDate, stratTimeDate);
    const days = type === "day" ? 1 : type === "week" ? 7 : monthDays || 0;
    const stepForMin = config.calendar.bodyWidth / (rangeTimeDiffInMin * days);
    let roundedStartTimeDate = new Date(
        new Date(stratTimeDate).setHours(startTime.hours + 1, 0, 0)
    );
    let roundedEndTimeDate = new Date(
        new Date(endTimeDate).setHours(endTime.hours, 0, 0)
    );
    if (showHalfHours && startTime.minutes < 30) {
        roundedStartTimeDate = new Date(
            new Date(stratTimeDate).setHours(startTime.hours, 30, 0)
        );
    }
    if (showHalfHours && endTime.minutes > 30) {
        roundedEndTimeDate = new Date(
            new Date(endTimeDate).setHours(endTime.hours, 30, 0)
        );
    }
    const startRoundDiff = differenceInMinutes(
        roundedStartTimeDate,
        stratTimeDate
    );
    let prevStepSum = 0;
    if (startRoundDiff) {
        timeLineValues = [
            ...timeLineValues,
            {
                value: format(stratTimeDate, timeFormat),
                step: 0,
                showValue:
                    type !== "month" &&
                    (stratTimeDate.getMinutes() === 0 ||
                        (showHalfHours && stratTimeDate.getMinutes() === 30)),
                showLine: true,
            },
        ];
        prevStepSum = startRoundDiff * stepForMin;
    }
    const roundedRangeTimeDiffInMin = differenceInMinutes(
        roundedEndTimeDate,
        roundedStartTimeDate
    );
    const max = showHalfHours
        ? roundedRangeTimeDiffInMin / 30
        : roundedRangeTimeDiffInMin / 60;
    for (let i = 0; i <= max; i++) {
        if (showHalfHours) {
            const value = addMinutes(roundedStartTimeDate, i * 30);
            timeLineValues = [
                ...timeLineValues,
                {
                    value: format(value, timeFormat),
                    step: prevStepSum,
                    showValue: type === "month" ? false : true,
                    showLine: true,
                },
            ];
            prevStepSum += stepForMin * 30;
        } else {
            const value = addMinutes(roundedStartTimeDate, i * 60);
            timeLineValues = [
                ...timeLineValues,
                {
                    value: format(value, timeFormat),
                    step: prevStepSum,
                    showValue: type === "month" ? false : true,
                    showLine: true,
                },
            ];
            prevStepSum += stepForMin * 60;
        }
    }
    const endRoundDiff = differenceInMinutes(endTimeDate, roundedEndTimeDate);
    if (endRoundDiff) {
        timeLineValues = [
            ...timeLineValues,
            {
                value: format(endTimeDate, timeFormat),
                step:
                    prevStepSum -
                    stepForMin * (showHalfHours ? 30 : 60) +
                    endRoundDiff * stepForMin,
                showValue:
                    type !== "month" &&
                    (endTimeDate.getMinutes() === 0 ||
                        (showHalfHours && endTimeDate.getMinutes() === 30)),
                showLine: true,
            },
        ];
    }
    if (type === "day") {
        return timeLineValues;
    } else if (type === "week") {
        return [0, 1, 2, 3, 4, 5, 6].reduce((acc, n) => {
            return [
                ...acc,
                ...timeLineValues.map((tl) => {
                    return {
                        value: tl.value,
                        step: tl.step + n * (config.calendar.bodyWidth / 7),
                        showValue: tl.showValue,
                        showLine: tl.showLine,
                    };
                }),
            ];
        }, [] as TimeLineValue[]);
    } else {
        return Array.from(Array(monthDays || 0).keys()).reduce((acc, n) => {
            return [
                ...acc,
                ...timeLineValues.map((tl) => {
                    return {
                        value: tl.value,
                        step:
                            tl.step +
                            n * (config.calendar.bodyWidth / (monthDays || 0)),
                        showValue: tl.showValue,
                        showLine: tl.showLine,
                    };
                }),
            ];
        }, [] as TimeLineValue[]);
    }
}

// returns number of non-working minutes in date range
function getNonWorkingMinutesInRange(
    startDate: Date,
    endDate: Date,
    workTimeStart: WorkTime,
    workTimeEnd: WorkTime
) {
    // if (startDate.getTime() > endDate.getTime()) {
    //     throw new Error("Bad range (start > end)");
    // }
    const diff = differenceInCalendarDays(endDate, startDate);
    let mins = 0;
    for (let i = 0; i <= diff; i++) {
        const startEndDateRange = {
            start: startDate,
            end: endDate,
        };
        const notWorkingRange1 = {
            start: new Date(addDays(startDate, i).setHours(0, 0, 0)),
            end: new Date(
                addDays(startDate, i).setHours(
                    workTimeStart.hours,
                    workTimeStart.minutes,
                    0
                )
            ),
        };
        const notWorkingRange2 = {
            start: new Date(
                addDays(startDate, i).setHours(
                    workTimeEnd.hours,
                    workTimeEnd.minutes,
                    0
                )
            ),
            end: new Date(addDays(startDate, i + 1).setHours(0, 0, 0)),
        };
        mins =
            mins +
            getOverlappingMinutesInterval(startEndDateRange, notWorkingRange1);
        mins =
            mins +
            getOverlappingMinutesInterval(startEndDateRange, notWorkingRange2);
    }
    return mins;
}

// returns time step in pixels (how many pixels represents one step in calendar when moving or resizing timebox)
function getTimeStepInPx(
    calendarType: CalendarDisplayType,
    workTimeStart: WorkTime,
    workTimeEnd: WorkTime,
    days?: number
) {
    const workTimeStartDate = new Date(0).setHours(
        workTimeStart.hours,
        workTimeStart.minutes
    );
    const workTimeEndDate = new Date(0).setHours(
        workTimeEnd.hours,
        workTimeEnd.minutes
    );
    switch (calendarType) {
        case "day":
            return (
                (config.calendar.bodyWidth /
                    differenceInMinutes(workTimeEndDate, workTimeStartDate)) *
                config.calendar[calendarType].timeStepInMinutes
            );
        case "week":
            return (
                (config.calendar.bodyWidth /
                    (differenceInMinutes(workTimeEndDate, workTimeStartDate) *
                        7)) *
                config.calendar[calendarType].timeStepInMinutes
            );
        case "month":
            return (
                (config.calendar.bodyWidth /
                    (differenceInMinutes(workTimeEndDate, workTimeStartDate) *
                        (days || 0))) *
                config.calendar[calendarType].timeStepInMinutes
            );
        default:
            return 0;
    }
}

function getResourceById(resourceId: string, events: CalendarEventType[]) {
    for (let i = 0; i < events.length; i++) {
        const resource =
            events[i].resources &&
            events[i].resources.find((r: ResourceType) => r.id === resourceId);
        if (resource) {
            return resource;
        }
    }
    return null;
}

function getEventById(eventId: string, events: CalendarEventType[]) {
    return events.find((e) => e.id === eventId) || null;
}

// returns true if given id belongs to event
function isEventId(eventId: string, events: CalendarEventType[]) {
    return events.find((e) => e.id === eventId) ? true : false;
}

function getEventByResourceId(resourceId: string, events: CalendarEventType[]) {
    return events.find((event) => {
        const res = event.resources.find((res) => res.id === resourceId);
        if (res) {
            return true;
        }
        return false;
    });
}

// groups events by template id
function groupEventsByTemplateId(
    events: CalendarEventType[],
    nonGroupingTemplateIds?: string[]
) {
    return events.reduce((acc, event) => {
        const templateGroupArray = acc.find((arr) => {
            return arr.find((e) => e.templateId === event.templateId);
        });
        if (
            templateGroupArray &&
            nonGroupingTemplateIds &&
            templateGroupArray[0].templateId &&
            !nonGroupingTemplateIds.includes(templateGroupArray[0].templateId)
        ) {
            return acc.map((arr) => {
                if (arr.find((e) => e.templateId === event.templateId)) {
                    return [...arr, event];
                } else {
                    return arr;
                }
            });
        } else {
            return [...acc, [event]];
        }
    }, [] as CalendarEventType[][]);
}

// groups resources by item id
function groupResourcesByItemId(resources: ResourceType[]) {
    return resources.map((r) => [r]);
    return resources.reduce((acc, resource) => {
        const resourceItemGroupArray = acc.find((arr) => {
            return arr.find((r) => r.itemId === resource.itemId);
        });
        if (resourceItemGroupArray) {
            return acc.map((arr) => {
                if (arr.find((r) => r.itemId === resource.itemId)) {
                    return [...arr, resource];
                } else {
                    return arr;
                }
            });
        } else {
            return [...acc, [resource]];
        }
    }, [] as ResourceType[][]);
}

// compose event row id from template id and event ids
function composeEventRowId(events: CalendarEventType[]) {
    let idstr = events.reduce((acc, event) => `${acc}-${event.id}`, "");
    return `${events[0].templateId}-${idstr}`;
}

// export const getEventColor = (event: CalendarEventType) => {
// 	// @ts-ignore
// 	return eventColorsByStatus[event.status] || eventColorsByStatus['default'];
// }

export const getEventColor = (event: CalendarEventType, calendarSettings: CalendarSettingsType): string => {
    const defaultColor = '15F05C';
    const cs = calendarSettings[event.calendarId];
    if (!cs) {
        return defaultColor;
    }
    if (event.orderLinkType === '0') {
        const resourceAddressnrSum = event.resources.reduce((acc, resource) => {
            return acc + (Number(resource.addressnr) || 0);
        }, 0);

        if (resourceAddressnrSum > 0) {
            return cs.ResourceStatusBooked || defaultColor;
        }
    }
	switch (event.status) {
        case '-1':
            if (event.orderLinkType === '3') {
                if (event.currentVisitors === 0 && (event.orderLink === '' || event.orderLink === null)) {
                    return cs.EventStatusNew || defaultColor;
                }
    
                if (event.currentVisitors > 0 && event.currentVisitors < event.maxVisitors && (event.orderLink !== '' || event.orderLink !== null)) {
                    return cs.EventStatusPartial || defaultColor;
                }
    
                if (event.currentVisitors === event.maxVisitors && (event.orderLink !== '' || event.orderLink !== null)) {
                    return cs.EventStatusFull || defaultColor;
                }

                return cs.EventStatusUnknown || defaultColor;
            }

            return defaultColor;
        case '0':
            return cs.EventStatusOffer || defaultColor;
        case '1':
            return cs.EventStatusOrder || defaultColor;
        case '2':
            return cs.EventStatusInvoice || defaultColor;
        case '3':
            return cs.EventStatusDebtor || defaultColor;
        case '4':
            return cs.EventStatusArchive || defaultColor;
        case '5':
            return cs.EventStatusTemplate || defaultColor;
        case '6':
            return cs.EventStatusParked || defaultColor;
        case '11':
            return cs.EventStatusDeleted || defaultColor;
        case '100':
            return cs.EventStatusOffer || defaultColor;
        case '101':
            return cs.EventStatusOrder || defaultColor;
        case '102':
            return cs.EventStatusInvoice || defaultColor;
        default:
            return cs.EventStatusDefault || defaultColor;
    }
}

export const getEventStatusName = (event: CalendarEventType): string => {
    if (event.orderLinkType === '0') {
        const resourceAddressnrSum = event.resources.reduce((acc, resource) => {
            return acc + (Number(resource.addressnr) || 0);
        }, 0);

        if (resourceAddressnrSum > 0) {
            return "resource_status_booked";
        }
    }
	switch (event.status) {
        case '-1':
            if (event.orderLinkType === '3') {
                if (event.currentVisitors === 0 && (event.orderLink === '' || event.orderLink === null)) {
                    return "event_status_new";
                }
    
                if (event.currentVisitors > 0 && event.currentVisitors < event.maxVisitors && (event.orderLink !== '' || event.orderLink !== null)) {
                    return "event_status_partial";
                }
    
                if (event.currentVisitors === event.maxVisitors && (event.orderLink !== '' || event.orderLink !== null)) {
                    return "event_status_full";
                }
    
                return "event_status_unknown";
            }

            return "event_status_default";
        case '0':
            return "event_status_offer";
        case '1':
            return "event_status_order";
        case '2':
            return "event_status_invoice";
        case '3':
            return "event_status_debtor";
        case '4':
            return "event_status_archive";
        case '5':
            return "event_status_template";
        case '6':
            return "event_status_parked";
        case '11':
            return "event_status_deleted";
        case '100':
            return "event_status_offer";
        case '101':
            return "event_status_order";
        case '102':
            return "event_status_invoice";
        default:
            return "event_status_default";
    }
}

// Calculate difference between two dates in ms or minutes
export const diffBetweenTwoDates = (start: Date, end: Date, ms: true): number => {
	const diffInMs = Math.abs(end.getTime() - start.getTime());
	if (ms) return diffInMs;
	return Math.floor((diffInMs / 1000) / 60);
}

export const shiftEventDatesToCalendarStartTime = (workTimeStart: WorkTime, pickedDate: Date, event: CalendarEventType): CalendarEventType => {
	pickedDate = new Date(pickedDate.setMinutes(workTimeStart.minutes));
	pickedDate = new Date(pickedDate.setHours(workTimeStart.hours));
	pickedDate = new Date(pickedDate.setSeconds(0));
	pickedDate = new Date(pickedDate.setMilliseconds(0));
	if (event.startDate < pickedDate) {
		throw Error('event.startDate < pickedDate');
	}

	const shiftDiff = diffBetweenTwoDates(pickedDate, event.startDate, true);

	const newEventStartDate = new Date(event.startDate?.getTime() - shiftDiff);
	const newEventEndDate = new Date(event.endDate?.getTime() - shiftDiff);
    newEventStartDate.setHours(newEventStartDate.getHours() + 1);
    newEventEndDate.setHours(newEventEndDate.getHours() + 1);
	event.startDate = newEventStartDate;
	event.endDate = newEventEndDate;

	const resources: ResourceType[] = event.resources.map((r) => {
		const newStartDate = new Date(r.startDate?.getTime() ? r.startDate?.getTime() - shiftDiff : newEventStartDate);
		const newEndDate = new Date(r.endDate?.getTime() ? r.endDate?.getTime() - shiftDiff : newEventEndDate);
        newStartDate.setHours(newStartDate.getHours() + 1);
        newEndDate.setHours(newEndDate.getHours() + 1);

		return {...r, startDate: newStartDate, endDate: newEndDate};
	});

	return {...event, resources} as CalendarEventType;
}

// export const eventMovable = (event: CalendarEventType | null) => {
//     if (event === null) return false;
//     return [3, 4].includes(Number(event?.status));
// }

export const eventMovable = (event: CalendarEventType | null): boolean => {
    if (event === null) return false;
    if (event.id === 'virtual') return true;
    // Contigented events
    if (event.status === '-1' && event.contingented) {
        if (event.orderLink === null && event.orderLinkType === '3') {
            // Confirm
            return true;
        }
        // if (event.orderLink && ['2', '3'].includes(event.orderLinkType)) {
        //     return false;
        // }

        return false;
    }
    // Private events
    if (!event.contingented) {
        if (['3', '4'].includes(event.status)) {
            return false;
        }
        if (event.orderLink === null && event.orderLinkType === '0') {
            return true;
        }
        if (event.orderLink && event.orderLinkType === '2') {
            // Confirm
            return true;
        }
    }
    
    return false;
}

export const confirmEventMove = (event: CalendarEventType | null): boolean => {
    if (event === null) return false;
    // Contigented events
    if (event.status === '-1' && event.contingented) {
        if (event.orderLink === null && event.orderLinkType === '3') {
            // Confirm
            return true;
        }
    }
    // Private events
    if (!event.contingented) {
        if (event.orderLink && event.orderLinkType === '2') {
            // Confirm
            return true;
        }
    }
    
    return false;
}

export const sortEventsByKey = (events: CalendarEventType[], key: EventSortOptionType): CalendarEventType[] => {
    let sortedEvents: CalendarEventType[] = Array.from(events);

    if (key === 'name') {
        sortedEvents.sort((a, b) => a.name.localeCompare(b.name));
    }

    if (key === 'startDate') {
        sortedEvents.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());
    }

    if (key === 'timestamp'){
        sortedEvents.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
    }

    return sortedEvents;
}

export const createSupplierListObject = (resource: ResourceType) => {
    if (!resource.addresses.length) return null;
    // if (resource.supplierlist !== null) return resource.supplierlist;
    const result: SupplierAvailabilityType = {};
    resource.addresses.forEach((address) => {
        result[address.id] = {
            invitedAt: resource.supplierlist?.[address.id]?.invitedAt || '',
            inviteFlag: resource.supplierlist?.[address.id]?.inviteFlag || false,
            acceptedAt: resource.supplierlist?.[address.id]?.acceptedAt || '',
            onHoldAt: resource.supplierlist?.[address.id]?.onHoldAt || '',
            deniedAt: resource.supplierlist?.[address.id]?.deniedAt || '',
            denyFlag: resource.supplierlist?.[address.id]?.denyFlag || false,
            confirmedAt: resource.supplierlist?.[address.id]?.confirmedAt || '',
            confirmFlag: resource.supplierlist?.[address.id]?.confirmFlag || false,
        }
    });

    return Object.keys(result).length === 0 ? null : result;
}

export const getStartEndTimeForMultipleCalendars = (calendarSettings: CalendarSettingsType): {
    workTimeStart: WorkTime,
    workTimeEnd: WorkTime
} => {
    let startHour = 24;
    let endHour = 0;

    for (const [key, settings] of Object.entries(calendarSettings as CalendarSettingsType)) {
        if (settings.workTimeStart.hours < startHour) {
            startHour = settings.workTimeStart.hours;
        }
        if (settings.workTimeEnd.hours > endHour) {
            endHour = settings.workTimeEnd.hours;
        }
    }

    return {
        workTimeStart: {
            hours: startHour,
            minutes: 0,
        },
        workTimeEnd: {
            hours: endHour,
            minutes: 0,
        }
    }
}

export const getFullDayEvents = (day: Date, events: CalendarEventType[]): CalendarEventType[] => {
    const dayStart = new Date(new Date(day).setHours(0, 0, 0, 0));
    const dayEnd = new Date(new Date(day).setHours(23, 0, 0, 0));
    return events.filter((e) => {
        return e.startDate.getTime() <= dayStart.getTime() && e.endDate.getTime() >= dayEnd.getTime();
    });
}

export {
    calcResourceStartEndDates,
    calcEventStartEndDates,
    getTimeLineValues,
    getNonWorkingMinutesInRange,
    getTimeStepInPx,
    getResourceById,
    getEventById,
    isEventId,
    getEventByResourceId,
    groupEventsByTemplateId,
    groupResourcesByItemId,
    composeEventRowId,
};
