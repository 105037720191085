import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import { toast, ToastContainer } from "react-toastify";

import FilterPanel from "./components/FilterPanel/FilterPanel";
import Calendar from "./components/Calendar/Calendar";
import {getAppConfigFromAPI, getCalendarSettingsFromAPI, getCalendarTranslationsFromAPI, getEventFromAPI, getFilterOptionsFromAPI, loadUserFromExternalAPI} from "./helpers/api";
import { setFilterOptions } from "./redux/actions/filterOptionsActions";
import { getPrintMode } from "./redux/selectors/printModeSelectors";
import CalendarPrintView from "./components/CalendarPrintView/CalendarPrintView";
import theme from "./theme";
import "./helpers/translate.ts";
import "./App.css";
import 'react-toastify/dist/ReactToastify.css';
import {setCreateEventMode, setSelectedEventIdForPlanning, setSelectedEvent, setCalendarSettings} from "./redux/actions/calendarActions";
import {setBranchFilter, setBranchGroupFilter, setCalendarIdFilter} from "./redux/actions/filterActions";
import {FiltersOptionsType} from "./redux/reducers/filterOptionsReducer";
import {setAppConfig} from "./redux/actions/appConfigActions";
import {getAppConfig} from "./redux/selectors/appConfigSelectors";
import {setUser} from "./redux/actions/userActions";
import {useTranslation} from "react-i18next";
import LoadingScreen from "./components/Loader/LoadingScreen";
import { isDevEnvironment } from "./helpers/helpers";

function App() {
	const dispatch = useDispatch();
	const appConfig = useSelector(getAppConfig);
	const printMode = useSelector(getPrintMode);
	const { i18n } = useTranslation();
	const [loading, setLoading] = React.useState(true);

	const loadQueryEvent = () => {
		const urlParams = new URLSearchParams(window.location.search);
		const eventId = urlParams.get('eventId');
		if (eventId) {
			dispatch(setCreateEventMode(true));
			// getEventForPlanningFromAPI(eventId, new Date(2021, 9, 6), new Date(2021, 9, 7));
			getEventFromAPI(eventId)
				.then((event) => {
					dispatch(setCalendarIdFilter(event.calendarId));
					dispatch(setSelectedEventIdForPlanning(eventId));
					// dispatch(setCreateEventMode(true));
					// dispatch(setSelectedEvent(event));
				})
				.catch((e) => console.log(e));
		}
	}

	const setQueryBranch = (filtersOpts: FiltersOptionsType) => {
		const urlParams = new URLSearchParams(window.location.search);
		const branchId = urlParams.get('fnr');
		if (branchId) {
			const branch = filtersOpts.branch.find((b) => b.value === branchId);
			if (branch) {
				dispatch(setBranchFilter(branchId));
				dispatch(setBranchGroupFilter(branch.groupValue));

				return branchId;
			}
		}
	}

	const loadUserData = (storeManagerURL: string) => {
		if (!storeManagerURL || storeManagerURL === '') return;
		loadUserFromExternalAPI(storeManagerURL)
			.then((user) => {
				dispatch(setUser(user));
				console.log(user);
				// const lang = user.language?.toLowerCase() || 'de';
				const lang = user.language || 'de';
				i18n.changeLanguage(lang);
				if (!user.islogged && !isDevEnvironment) {
					toast.warning('Unauthenticated', {autoClose: 2000});
					setTimeout(() => {
						window.location.href = storeManagerURL;
					}, 2000);
				} else {
					setLoading(false);
				}
			})
			.catch((err) => {
				console.log(err);
				if (isDevEnvironment) {
					dispatch(setUser({islogged:false, facturabill_modify: true}));
					setLoading(false);
				}
			});
	}

    useEffect(() => {
        getFilterOptionsFromAPI()
            .then((filtersOpts) => {
                dispatch(setFilterOptions(filtersOpts));
				setQueryBranch(filtersOpts);
				loadQueryEvent();
            })
            .catch((err) => console.log(err));
    }, [dispatch]);

	useEffect(() => {
		getAppConfigFromAPI()
			.then((config) => {
				dispatch(setAppConfig(config));
			})
			.catch((err) => console.log(err));
	}, []);

	useEffect(() => {
		getCalendarSettingsFromAPI()
			.then((config) => {
				dispatch(setCalendarSettings(config));
			})
			.catch((err) => console.log(err));
	}, [dispatch]);

	useEffect(() => {
		getCalendarTranslationsFromAPI()
			.then((translations) => {
				for (const [_, language] of Object.entries(translations)) {
					i18n.addResources(language.ISOCode, 'translation', language.messages);
				  }
			})
			.catch((err) => console.log(err));
	}, []);

	useEffect(() => {
		loadUserData(appConfig.storeManagerUrl);
		const intervalId = setInterval(() => {
			loadUserData(appConfig.storeManagerUrl);
		}, 60000);

		return () => clearInterval(intervalId);
	}, [dispatch, appConfig]);

    return (
		<>
        <ThemeProvider theme={theme}>
            {loading && (
				<LoadingScreen />
			)}
			{!loading && (
				<div className="app-container">
					{!printMode ? (
						<>
							<FilterPanel />
							<Calendar />
						</>
					) : (
						<CalendarPrintView />
					)}
				</div>
			)}
        </ThemeProvider>
		<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="colored"
		/>
		</>
    );
}

export default App;
