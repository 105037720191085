import { FilterOptsActionTypes } from "../actionTypes";
import {TemplateWithGroupType} from "../../helpers/dataProcessor";

export interface GrouppedTemplateType {
	label: string;
    templateGroupNr?: string;
	options: TemplateWithGroupType[];
}

export interface FiltersOptionsType {
    calendars: { value: string; label: string }[];
    branchGroup: { value: string; label: string }[];
    branch: { value: string; label: string; groupValue: string }[];
    resourceGroup: { value: string; label: string }[];
    resource: { value: string; label: string; groupValue: string, branchId: string }[];
    template: { value: string; label: string, group: string, groupNr: string, branchNr: string }[];
    templateGroup: { value: string; label: string }[];
	grouppedTemplates: GrouppedTemplateType[];
    suppliers: { value: string; label: string }[];
}

const initFiltersOpts = {
    calendars: [],
    branchGroup: [],
    branch: [],
	template: [],
	grouppedTemplates: [],
	templateGroup: [],
    resourceGroup: [],
    resource: [],
    suppliers: [],
};

function filterOptionsReducer(
    state: FiltersOptionsType = initFiltersOpts,
    action: { type: string; payload?: any }
) {
    switch (action.type) {
        case FilterOptsActionTypes.SET_FILTER_OPTIONS:
            // console.log("REDUX - filterOptions set all");
            return action.payload.value;
        case FilterOptsActionTypes.SET_FILTER_OPT_BRANCH_GROUP:
            // console.log("REDUX - filterOptions branchGroup");
            return { ...state, branchGroup: action.payload.value };
        case FilterOptsActionTypes.SET_FILTER_OPT_BRANCH:
            // console.log("REDUX - filterOptions branch");
            return { ...state, branch: action.payload.value };
        case FilterOptsActionTypes.SET_FILTER_OPT_RESOURCE_GROUP:
            // console.log("REDUX - filterOptions respurceGroup");
            return { ...state, resourceGroup: action.payload.value };
        case FilterOptsActionTypes.SET_FILTER_OPT_RESOURCE:
            // console.log("REDUX - filterOptions resource");
            return { ...state, resource: action.payload.value };
        case FilterOptsActionTypes.SET_FILTER_OPT_TEMPLATE:
            // console.log("REDUX - filterOptions template");
            return { ...state, template: action.payload.value };
        case FilterOptsActionTypes.SET_FILTER_OPT_SUPPLIERS:
            // console.log("REDUX - filterOptions suppliers");
            return { ...state, suppliers: action.payload.value };
        default:
            return state;
    }
}

export default filterOptionsReducer;
